import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import MKBadge from "components/MKBadge";
import axios from "axios";

import conf from "conf/properties.json";
// logo
import nvidiaLogo from "assets/images/nvidia_logo.png";

function Machine(props) {
  const { Paginated, ItemLimit, ItemSort, RefreshInterval } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sort] = useState(ItemSort);
  const [limit] = useState(ItemLimit.toString());
  const [totalPage, setTotalpage] = useState(1);

  const [url, setURL] = useState(() => {
    if (props.AuthType === "Rented") {
      return `${conf.API_SERVER}/v1/machine/all?rented=true&page=1&limit=${limit}&sort=${sort}`;
    }

    if (props.AuthType === "Owned") {
      return `${conf.API_SERVER}/v1/machine/all?page=1&limit=${limit}&sort=${sort}`;
    }

    return `${conf.API_SERVER}/v1/public/machine/all?page=1&limit=${limit}&sort=${sort}`;
  });

  const [countURL] = useState(() => {
    if (props.AuthType === "Rented") {
      return `${conf.API_SERVER}/v1/machine/count?rented=true&limit=${limit}`;
    }

    if (props.AuthType === "Owned") {
      return `${conf.API_SERVER}/v1/machine/count?limit=${limit}`;
    }

    return `${conf.API_SERVER}/v1/public/machine/count?limit=${limit}`;
  });

  const [customHeader] = useState(() => {
    if (props.AuthType === "Rented" || props.AuthType === "Owned") {
      return { Authorization: sessionStorage.getItem("token") };
    }

    return {};
  });

  const handleLoadMore = () => {
    setPage(page + 1);
    if (props.AuthType === "Rented") {
      setURL(
        `${conf.API_SERVER}/v1/machine/all?rented=true&page=${page + 1}&limit=${limit}&sort=${sort}`
      );
    } else if (props.AuthType === "Owned") {
      setURL(`${conf.API_SERVER}/v1/machine/all?page=${page + 1}&limit=${limit}&sort=${sort}`);
    } else {
      setURL(
        `${conf.API_SERVER}/v1/public/machine/all?page=${page + 1}&limit=${limit}&sort=${sort}`
      );
    }
  };

  const handlePagination = (_, val) => {
    setPage(val);
    if (props.AuthType === "Rented") {
      setURL(
        `${conf.API_SERVER}/v1/machine/all?rented=true&page=${val}&limit=${limit}&sort=${sort}`
      );
    } else if (props.AuthType === "Owned") {
      setURL(`${conf.API_SERVER}/v1/machine/all?page=${val}&limit=${limit}&sort=${sort}`);
    } else {
      setURL(`${conf.API_SERVER}/v1/public/machine/all?page=${val}&limit=${limit}&sort=${sort}`);
    }
  };

  const fetchData = () => {
    axios
      .get(url, { headers: customHeader })
      .then((response) => {
        const parsedData = [];

        response.data.data.forEach((item) => {
          const newItem = {};
          newItem.CpuInfo = JSON.parse(item.CpuInfo);
          newItem.GpuInfo = JSON.parse(item.GpuInfo);
          newItem.MemoryInfo = JSON.parse(item.MemoryInfo);
          newItem.ID = item.ID;
          newItem.Status = item.Status;
          newItem.Occupied = item.Occupied;
          newItem.IsActive = item.IsActive;
          parsedData.push(newItem);
        });

        if (Paginated) {
          setData([...parsedData]);
        } else {
          setData([...data, ...parsedData]);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    axios
      .get(countURL, { headers: customHeader })
      .then((response) => {
        setTotalpage(response.data.total);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const comInterval = setInterval(fetchData, RefreshInterval);
    return () => clearInterval(comInterval);
  }, []);

  const renderMachine = data.map(({ ID, CpuInfo, GpuInfo, MemoryInfo, IsActive, Occupied }) => (
    <MKBox
      key={ID}
      shadow="lg"
      borderRadius="lg"
      width="100%"
      bgColor="white"
      alignSelf="center"
      my={3}
      py={6}
      border="thin solid #f0f2f5"
      sx={{
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(999px) rotateX(0deg) translate3d(0rem, 0rem, 0.5rem)",
        },
      }}
    >
      <Container sx={{ width: "100% !important", margin: "0px !important" }}>
        <Link to={`/machine/details/${ID}`}>
          <Grid container spacing={3}>
            <Grid item xs={2} lg={2} align="center">
              <MKAvatar variant="rounded" size="xxl" alt="Avatar" src={nvidiaLogo} />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ paddingTop: "0rem !important" }}>
                  {Occupied ? (
                    <MKBadge
                      badgeContent="사용중"
                      variant="contained"
                      size="sm"
                      color="secondary"
                      container
                    />
                  ) : (
                    <MKBadge
                      badgeContent="사용가능"
                      variant="contained"
                      size="sm"
                      color="success"
                      container
                    />
                  )}
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0rem !important" }}>
                  <MKTypography variant="h2">
                    {IsActive ? (
                      <Icon color="success" fontSize="small">
                        circle
                      </Icon>
                    ) : (
                      <Icon fontSize="small" sx={{ color: "#f44336" }}>
                        circle
                      </Icon>
                    )}
                    &nbsp;{GpuInfo[0].ProductName}
                  </MKTypography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0rem !important" }}>
                  <MKTypography variant="h3">{`${GpuInfo.length} * GPU`}</MKTypography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0rem !important" }}>
                  <MKTypography variant="a">
                    {`${CpuInfo.processors[0].model} ${CpuInfo.total_threads} vCPU`}
                  </MKTypography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0rem !important" }}>
                  <MKTypography variant="a">
                    {Math.ceil(MemoryInfo.total_physical_bytes / 1073741824)}&nbsp;GB&nbsp;RAM
                  </MKTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </Container>
    </MKBox>
  ));

  return (
    <MKBox>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          {renderMachine}
        </Grid>
        <Grid item xs={12} lg={12}>
          <Stack spacing={2} alignItems="center">
            {Paginated ? (
              <Pagination
                count={totalPage}
                page={page}
                onChange={handlePagination}
                showFirstButton
                showLastButton
                align="center"
              />
            ) : (
              <Button onClick={handleLoadMore}>load more...</Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </MKBox>
  );
}

Machine.propTypes = {
  AuthType: propTypes.string,
  Paginated: propTypes.bool,
  ItemLimit: propTypes.number,
  ItemSort: propTypes.string,
  RefreshInterval: propTypes.number,
};

Machine.defaultProps = {
  AuthType: "",
  Paginated: false,
  ItemLimit: 5,
  ItemSort: "is_active desc",
  RefreshInterval: 15000,
};

export default Machine;
