export default {
  // brand: {
  //   name: "Redgate",
  //   image: redgatelogo,
  //   route: "/",
  // },
  socials: [],
  menus: [],
  copyright: <></>,
};
