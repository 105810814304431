import { useState } from "react";
import propTypes from "prop-types";
// import { useNavigate } from "react-router-dom";

import { Modal, Grid, Container, RadioGroup, Radio, Select, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";

import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";
import axios from "axios";

import conf from "conf/properties.json";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function RentMachineModal(props) {
  const imageTemplates = [
    {
      image: "tensorflow/tensorflow:latest-gpu-jupyter",
      portmap: JSON.stringify({ "8888/tcp": "Jupyter" }),
      env: JSON.stringify([`JUPYTER_TOKEN=${sessionStorage.getItem("email")}`]),
      name: "Tensorflow-jupyter ver:latest",
      command: "[]",
    },
    {
      image: "aleet/redgate-faceapp:1.0",
      portmap: JSON.stringify({ "7860/tcp": "Faceapp" }),
      env: "[]",
      name: "redgate-faceapp:1.0",
      command: "[]",
    },
    {
      image: "ashleykza/fooocus:2.4.3",
      portmap: JSON.stringify({ "3001/tcp": "Fooocus", "8888/tcp": "JupyterLab" }),
      env: JSON.stringify([`VENV_PATH="/workspace/venvs/fooocus"`]),
      name: "ashleykza/fooocus:2.4.3",
      command: "[]",
    },
    {
      image: "ashleykza/kohya:24.1.4",
      portmap: JSON.stringify({ "3001/tcp": "Kohya", "8888/tcp": "JupyterLab" }),
      env: "[]",
      name: "ashleykza/kohya:24.1.4",
      command: "[]",
    },
    {
      image: "rocker/verse:latest",
      portmap: JSON.stringify({ "8787/tcp": "Rstudio" }),
      env: JSON.stringify([`PASSWORD=${sessionStorage.getItem("email")}`]),
      name: "Rstudio ver:latest",
      command: "[]",
    },
    {
      image: "linuxserver/kdenlive:latest",
      portmap: JSON.stringify({ "3000/tcp": "kdenlive" }),
      env: "[]",
      name: "Kdenlive Video Editor",
      command: "[]",
    },
    {
      image: "tverous/pytorch-notebook:latest",
      portmap: JSON.stringify({ "8888/tcp": "Jupyter" }),
      env: JSON.stringify([`JUPYTER_TOKEN=${sessionStorage.getItem("email")}`]),
      name: "Pytorch-Juptyer ver:latest",
      command: JSON.stringify([
        "jupyter",
        "notebook",
        "--ip=0.0.0.0",
        "--allow-root",
        '--notebook-dir="/shared_data"',
      ]),
    },
  ];

  const { idVal, switchHandle, switchVal } = props;
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [command, setCommand] = useState("[]");
  const [error, setError] = useState("");
  const [mode, setMode] = useState("template");
  const [portmap, setPortmap] = useState("{}");
  const [env, setEnv] = useState("[]");
  const [template, setTemplate] = useState("{}");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleImage = (e) => setImage(e.target.value);
  const handleCommand = (e) => setCommand(e.target.value);
  const handleTemplate = (e) => {
    setTemplate(e.target.value);
  };
  const handlePortmap = (e) => setPortmap(e.target.value);
  const handleEnv = (e) => setEnv(e.target.value);

  const handleRun = () => {
    let msg = "";
    if (mode === "template") {
      const JsonTemplate = JSON.parse(template);
      msg = JSON.stringify({
        Image: JsonTemplate.image,
        Command: JsonTemplate.command,
        PortMap: JsonTemplate.portmap,
        Env: JsonTemplate.env,
      });
    } else {
      if (image === "") {
        setError("이미지를 등록해주세요.");
        return;
      }
      msg = JSON.stringify({
        Image: image,
        Command: command,
        PortMap: portmap,
        Env: env,
      });
    }

    axios
      .post(`${conf.API_SERVER}/v1/machine/${idVal}/job`, msg, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (resp.data === "Success") {
          setOpen(false);
          switchHandle(!switchVal);
        }
      })
      .catch((err) => setError(err.response.data.error));
  };

  const handleChange = (e) => {
    setMode(e.target.value);
  };

  console.log(error);

  return (
    <>
      <MKButton variant="contained" color="primary" onClick={handleOpen}>
        Rent Device
      </MKButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox sx={style}>
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={12}>
                <MKTypography id="modal-modal-title" variant="h3">
                  실행 옵션
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <MKTypography id="modal-modal-description" variant="h5">
                  도커 형태의 컨테이너 실행 옵션을 선택 해주세요.
                </MKTypography>
                {error ? (
                  <MKAlert textAlign="center" mt={2} color="error">
                    {error}
                  </MKAlert>
                ) : null}
              </Grid>
              <Grid item xs={12} lg={12}>
                <RadioGroup
                  row
                  aria-labelledby="controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={mode}
                  onChange={handleChange}
                >
                  <FormControlLabel value="template" control={<Radio />} label="template" />
                  {/* TEMP: 임시적으로 커스텀 도커 컨테이너 방법 비활성화 */}
                  <FormControlLabel value="custom" control={<Radio />} label="custom" />
                </RadioGroup>
              </Grid>
              {mode === "custom" ? (
                <>
                  <Grid item xs={12} lg={12}>
                    <MKInput
                      variant="outlined"
                      label="도커 이미지"
                      placeholder="eg. rocker/verse:latest"
                      onChange={handleImage}
                      fullWidth
                      sx={{ mt: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <MKInput
                      variant="outlined"
                      label="커맨드"
                      placeholder='eg. ["bash", "-c", "ls"]'
                      onChange={handleCommand}
                      fullWidth
                      sx={{ mt: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <MKInput
                      variant="outlined"
                      label="포트 맵핑"
                      placeholder='eg. {"8888/tcp": "Jupyter", "port/protocol": "name" }'
                      onChange={handlePortmap}
                      fullWidth
                      sx={{ mt: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <MKInput
                      variant="outlined"
                      label="도커환경 셋팅(-e)"
                      placeholder='eg. ["PASSWORD=admin", "ENV=VALUE"]'
                      onChange={handleEnv}
                      fullWidth
                      sx={{ mt: 3 }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} lg={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="template-select-label">도커 이미지</InputLabel>
                    <Select
                      labelId="template-select-label"
                      variant="outlined"
                      fullWidth
                      value={template}
                      label="도커 이미지"
                      onChange={handleTemplate}
                      sx={{
                        height: "2.5rem",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {imageTemplates.map((Template) => (
                        <MenuItem key={Template.image} value={JSON.stringify(Template)}>
                          {Template.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} lg={12} align="end">
                <MKButton variant="contained" color="primary" align="center" onClick={handleRun}>
                  Run Job
                </MKButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Modal>
    </>
  );
}

RentMachineModal.propTypes = {
  idVal: propTypes.string.isRequired,
  switchHandle: propTypes.func.isRequired,
  switchVal: propTypes.bool.isRequired,
};

export default RentMachineModal;
