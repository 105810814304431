import propTypes from "prop-types";

import { Grid } from "@mui/material";
// import MKTypography from "components/MKTypography";

function Logs(props) {
  const { content, rows } = props;

  return (
    <Grid item xs={12} lg={12}>
      <textarea
        style={{ width: "100%", color: "white", backgroundColor: "black" }}
        value={content}
        width="100%"
        rows={rows}
        readOnly
      />
    </Grid>
  );
}

Logs.propTypes = {
  content: propTypes.string.isRequired,
  rows: propTypes.string,
};

Logs.defaultProps = {
  rows: "40",
};

export default Logs;
