import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Container, Card, Grid, Typography } from "@mui/material";
import BaseLayout from "custom/layout/Pages/BaseLayout";
import MKTypography from "components/MKTypography";

import axios from "axios";
import MKBox from "components/MKBox";

import RentMachineModal from "custom/components/Modals/RentMachine";
import StopRentingButton from "custom/components/Buttons/StopRenting";
import MKButton from "components/MKButton";
import Logs from "custom/components/Items/Logs";

import conf from "conf/properties.json";

function MachineDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [logContent, setLogContent] = useState("");
  const [rentState, setRentstate] = useState(false);
  const [isAuth, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOccupied, setOccupied] = useState(false);
  const [isActive, setActive] = useState(false);
  const [linkedDomains, setLinkedDomains] = useState({});
  const [envImage, setEnvImage] = useState("");
  const sshPort = 40000 + Number(id);
  const sshText = `# SSH Command \nssh -p ${sshPort} root@ssh1.redgate-gpu.com`;
  const refreshTime = 5000;

  const feedbackHandler = (state) => {
    setRentstate(state);
  };

  const rentButtonHanler = (s) => {
    // Check if Machine is active
    if (!isActive) {
      return (
        <MKButton variant="contained" color="secondary">
          Inactive
        </MKButton>
      );
    }

    // Check if Rentee is current user
    if (!isAuth && isOccupied) {
      return (
        <MKButton variant="contained" color="secondary">
          Unauthorized
        </MKButton>
      );
    }

    // currently using machine
    if (s === "RunningJob") {
      return <StopRentingButton idVal={id} switchHandle={feedbackHandler} switchVal={rentState} />;
    }

    // is loading
    if (isLoading) {
      return (
        <MKButton variant="contained" color="secondary">
          Loading
        </MKButton>
      );
    }

    // else return rent button
    return <RentMachineModal idVal={id} switchHandle={feedbackHandler} switchVal={rentState} />;
  };

  const fetchData = () => {
    axios
      .get(`${conf.API_SERVER}/v1/machine/${id}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        const GpuInfo = JSON.parse(response.data.data.GpuInfo);
        const CpuInfo = JSON.parse(response.data.data.CpuInfo);
        const MemoryInfo = JSON.parse(response.data.data.MemoryInfo);

        setData({
          GPU: GpuInfo[0].ProductName,
          CPU: CpuInfo.processors[0].model,
          vCPU: CpuInfo.total_threads,
          "RAM(GB)": Math.ceil(MemoryInfo.total_physical_bytes / 1073741824),
          Status: response.data.data.Status,
        });

        setAuth(response.data.auth);
        // TEMP: 임시적으로 주피터만 공유
        setLinkedDomains(JSON.parse(response.data.data.LinkedDomains));
        setLoading(response.data.data.IsLoading);
        setOccupied(response.data.data.Occupied);
        setActive(response.data.data.IsActive);
        setEnvImage(response.data.data.Image);
      })
      .catch((err) => console.log(err));
  };

  const fetchLog = () => {
    axios
      .get(`${conf.API_SERVER}/v1/machine/${id}/job/logs`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((resp) => {
        setLogContent(resp.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [rentState]);

  useEffect(() => {
    fetchLog();
  }, []);

  useEffect(() => {
    const comInterval = setInterval(fetchData, refreshTime);
    return () => clearInterval(comInterval);
  }, []);

  useEffect(() => {
    const comInterval = setInterval(fetchLog, refreshTime);
    return () => clearInterval(comInterval);
  }, []);

  const renderMachine = Object.keys(data).map((key) => (
    <Grid item key={key} xs={12} lg={12}>
      <MKTypography>
        {key}: {data[key]}
      </MKTypography>
    </Grid>
  ));

  const renderDomains = Object.keys(linkedDomains).map((key) => (
    <Grid item key={key} xs={12} lg={12}>
      <Typography
        component="a"
        href={`https://${linkedDomains[key]}`}
        variant="body1"
        target="_blank"
        rel="noreferrer"
        fontWeight="regular"
        sx={{
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;{key}: {linkedDomains[key]}
      </Typography>
    </Grid>
  ));

  return (
    <BaseLayout
      title="Machine Detail"
      breadcrumb={[{ label: "Machine" }, { label: "Details", route: `/machine/details/${id}` }]}
    >
      <Card>
        <MKBox
          mt={4}
          mb={4}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid container spacing={6}>
              {renderMachine}
              {isAuth ? (
                <Grid item key="Env" xs={12} lg={12}>
                  <MKTypography>Environment: {envImage}</MKTypography>
                </Grid>
              ) : null}
              {isAuth ? (
                <Grid item key="Domains" xs={12} lg={12}>
                  <MKTypography>Domains: {renderDomains}</MKTypography>
                </Grid>
              ) : null}
              {isAuth ? (
                <Grid item key="ssh" xs={12} lg={12} mt={1}>
                  <MKTypography variant="body1">
                    SSH 설치 및 작동중인 환경이면 아래로 SSH 접속이 가능합니다.
                  </MKTypography>
                  <MKTypography variant="body2">
                    SSH 설치 작동 여부는 도커 환경(위에 Environment칸)을 참고하여 확인해 주세요.
                  </MKTypography>
                  <textarea
                    style={{ width: "100%", color: "white", backgroundColor: "gray" }}
                    value={sshText}
                    width="100%"
                    rows="2"
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} lg={12} align="end">
                {rentButtonHanler(data.Status)}
              </Grid>
              {isAuth ? <Logs content={logContent} /> : null}
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </BaseLayout>
  );
}

export default MachineDetail;
