/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

// Material Kit 2 React routes
// import routes from "routes";

// Custom Routes
import GetRoutes from "routes";

// Custom Pages
import SignIn from "custom/pages/SignIn";
import SignOut from "custom/components/SignOut";
// import SignUp from "custom/layout/Pages/Authentication/Sign-Up";
import Main from "custom/layout/Pages/Main";
import MachineDetail from "custom/layout/Pages/Sub/Machine-Detail";
import PrivateRoute from "custom/components/PrivateRoute";
import MyMachines from "custom/layout/Pages/Sub/My-Machines";

const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route && !route.manual) {
      if (route.private) {
        return (
          <Route key={route.key} element={<PrivateRoute />}>
            <Route exact path={route.route} element={route.component} />;
          </Route>
        );
      }
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });

export default function App() {
  const { pathname } = useLocation();
  const [routes, setRoutes] = useState(GetRoutes(sessionStorage.getItem("authenticated")));

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const onStorage = () => {
    const authVal = sessionStorage.getItem("authenticated");
    setRoutes(GetRoutes(authVal));
  };

  useEffect(() => {
    // window.addEventListener("change", onStorage);
    window.addEventListener("storage", onStorage);

    return () => {
      // window.removeEventListener("change", onStorage);
      window.addEventListener("storage", onStorage);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/main" />} />
        {/* custom routes */}
        <Route path="/main" element={<Main />} />
        {/* <Route path="/pages/authentication/sign-up" element={<SignUp />} /> */}
        <Route path="/pages/authentication/sign-in" element={<SignIn />} />
        <Route path="/pages/authentication/sign-out" element={<SignOut />} />
        <Route element={<PrivateRoute />}>
          <Route path="/machine/details/:id" element={<MachineDetail />} />
          <Route path="/mypage/machines" element={<MyMachines />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
