/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/
import Icon from "@mui/material/Icon";

import MyMachines from "custom/layout/Pages/Sub/My-Machines";
import SignIn from "custom/pages/SignIn";
import SignOut from "custom/components/SignOut";
// import AuthManage from "custom/layout/Pages/Authentication/AuthManage";
// sessionStorage.setItem("authenticated", true);
// sessionStorage.setItem("token", token);

const GetRoutes = (Auth) => {
  // TODO: conditional routes vs basic routes
  // const routes = [
  //   {
  //     name: Auth ? "My Machines" : null,
  //     icon: Auth ? <Icon>devices</Icon> : null,
  //     private: true,
  //     route: "/mypage/machines",
  //     component: <MyMachines />,
  //   },
  //   {
  //     name: Auth ? "sign out" : "sign in",
  //     icon: Auth ? <Icon>logout</Icon> : <Icon>login</Icon>,
  //     private: false,
  //     route: "/pages/authentication/sign-in",
  //     component: <AuthManage Auth={Auth} />,
  //   },
  // ];

  const routes = [
    {
      name: "sign in",
      icon: <Icon>login</Icon>,
      private: false,
      manual: true,
      route: "/pages/authentication/sign-in",
      component: <SignIn />,
    },
  ];

  if (Auth) {
    return [
      {
        name: "My Machines",
        icon: <Icon>devices</Icon>,
        private: true,
        manual: true,
        route: "/mypage/machines",
        component: <MyMachines />,
      },
      {
        name: "sign out",
        icon: <Icon>logout</Icon>,
        private: false,
        manual: true,
        route: "/pages/authentication/sign-out",
        component: <SignOut />,
      },
    ];
  }

  return routes;
};

export default GetRoutes;
