/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "custom/layout/Navbars/DefaultNavbar";
import DefaultFooter from "custom/layout/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/gpu_image.png";
import Machine from "custom/components/Items/Machine";
import GetRoutes from "routes";

function Main() {
  const routes = GetRoutes(sessionStorage.getItem("authenticated"));

  function MouseOver(e) {
    e.target.style.color = "blue";
  }

  function MouseOut(e) {
    e.target.style.color = "";
  }
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={5} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={3}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Redgate GPU{" "}
            </MKTypography>
            <MKTypography
              variant="h4"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              GPU 렌탈 서비스
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox>
          <MKTypography variant="h5" textAlign="center">
            렌탈 자원 활용을 위한 여러 환경을 제공합니다
          </MKTypography>
          <MKTypography variant="body2" textAlign="center" sx={{ pt: 1 }}>
            추가 필요 환경은&nbsp;
            <Link
              href="https://hub.docker.com/search?image_filter=official&q=&type=image"
              variant="body2"
              target="_blank"
              rel="noreferrer"
              onMouseEnter={MouseOver}
              onMouseLeave={MouseOut}
              underline="always"
              sx={{ textDeocration: "underline !important" }}
            >
              <u>DockerHub</u>
            </Link>
            에서 확인하실 수 있습니다
          </MKTypography>
          <MKTypography variant="body2" textAlign="center">
            환경 탬플릿 추가 요청은 hongmoonc@rgsoft.co.kr에 가능합니다
          </MKTypography>
        </MKBox>
      </Card>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 2,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Machine Paginated ItemLimit={5} />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Main;
