import propTypes from "prop-types";

import MKButton from "components/MKButton";
import conf from "conf/properties.json";
import axios from "axios";

function StopRenting(props) {
  const { idVal, switchHandle, switchVal } = props;

  const handleStop = () => {
    axios
      .patch(
        `${conf.API_SERVER}/v1/machine/${idVal}/job`,
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        switchHandle(!switchVal);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <MKButton variant="contained" color="primary" onClick={handleStop}>
        Stop
      </MKButton>
    </>
  );
}

StopRenting.propTypes = {
  idVal: propTypes.string.isRequired,
  switchHandle: propTypes.func.isRequired,
  switchVal: propTypes.bool.isRequired,
};

export default StopRenting;
