import { Card, Container, Grid } from "@mui/material";
import BaseLayout from "custom/layout/Pages/BaseLayout";
import MKTypography from "components/MKTypography";

import Machine from "custom/components/Items/Machine";

function MyMachines() {
  return (
    <BaseLayout title="My Machines" breadcrumb={[{ label: "MyPage" }, { label: "Machines" }]}>
      <Card>
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="start" mx="auto">
            <MKTypography variant="h3">Owned</MKTypography>
          </Grid>
          <Grid container item xs={12} lg={12} justifyContent="start" mx="auto">
            <Machine AuthType="Owned" Paginated />
          </Grid>
          <Grid container item xs={12} lg={12} justifyContent="start" mx="auto">
            <MKTypography variant="h3">Rented</MKTypography>
          </Grid>
          <Grid container item xs={12} lg={12} justifyContent="start" mx="auto">
            <Machine AuthType="Rented" Paginated />
          </Grid>
        </Container>
      </Card>
    </BaseLayout>
  );
}

export default MyMachines;
