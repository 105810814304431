/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";
// import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// // @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "custom/layout/Navbars/DefaultNavbar";
import SimpleFooter from "custom/layout/Footers/SimpleFooter";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";
import sha256 from "crypto-js/sha256";

import MKAlert from "components/MKAlert";

import GetRoutes from "routes";
import conf from "conf/properties.json";

function SignInBasic() {
  const routes = GetRoutes(sessionStorage.getItem("authenticated"));
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  function validateEmail() {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleLogin = async () => {
    if (!validateEmail()) {
      setError("Invalid Email");
      return;
    }

    const temp = email.concat(":", password);
    const token = sha256(temp).toString();

    await axios
      .get(`${conf.API_SERVER}/v1/login`, {
        headers: {
          Authorization: token,
        },
      })
      .then((resp) => {
        if (resp.data.msg === "Success") {
          sessionStorage.setItem("authenticated", true);
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("email", email);
          navigate("/main");
        }
      })
      .catch(() => setError("Login Failed."));
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox pt={4} pb={3} px={3}>
                <MKTypography
                  variant="h2"
                  fontWeight="medium"
                  color="info"
                  mt={1}
                  mb={6}
                  textAlign="center"
                >
                  Welcome
                </MKTypography>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput type="email" label="Email" onChange={handleEmail} fullWidth />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="password" label="Password" onChange={handlePassword} fullWidth />
                  </MKBox>
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    {/* <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MKTypography> */}
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" onClick={handleLogin} fullWidth>
                      sign in
                    </MKButton>
                    {error ? (
                      <MKAlert textAlign="center" mt={2} color="error">
                        {error}
                      </MKAlert>
                    ) : null}
                  </MKBox>
                  {/* <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      계정이 없으십니까?&nbsp;
                      <MKTypography
                        component={Link}
                        to="/pages/authentication/sign-up"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        회원가입
                      </MKTypography>
                    </MKTypography>
                  </MKBox> */}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default SignInBasic;
